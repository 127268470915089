import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class ResidentTypesProvider extends HttpRequest {
  getItems (query) {
    this.setHeader(getAuthToken())
    return this.get('/resident-types', query)
  }

  getItemById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/resident-types/${id}`)
  }

  create (payload) {
    this.setHeader(getAuthToken())
    return this.post('/resident-types', payload)
  }

  updateItemById (payload) {
    this.setHeader(getAuthToken())
    return this.patch(`/resident-types/${payload.id}`, payload)
  }

  deleteItemById (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/resident-types/${id}`)
  }
}

export default ResidentTypesProvider
